import React from "react";
import { Button } from "./ui/button";

export default function Navbar() {
  return (
    <div className="p-4 max-w-7xl mx-auto flex  flex-row items-center justify-between ">
      <div>
        <h1 className="text-2xl text-white uppercase poppins-bold">
          Portfolio
        </h1>
      </div>
      <a href="mailto:info@nagyervin.com">
        <button className="text-white border-white border py-1 hover:bg-white hover:text-black transition-all cursor-pointer md:px-4 px-6 rounded-lg">
          E-mail me
        </button>
      </a>
    </div>
  );
}
